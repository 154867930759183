<template>
    <div>
        <div class="mt-10" :class="$vuetify.breakpoint.mobile ? '' : 'container'">
            <v-row>
                <v-col
                    cols="12"
                    lg="3"
                    sm="3"
                    @click="toPage(item.slug)"
                    class="d-flex justify-center"
                    v-for="(item, index) in links"
                    :key="index">
                    <div class="text-center">
                        <v-card class="primary home-card d-flex justify-center" flat>
                            <v-icon color="secondary" class="home-icon">{{ item.icon }}</v-icon>
                        </v-card>
                        <h3 class="third--text mt-2">{{item.title}}</h3>
                    </div>
                </v-col>
            </v-row>
        </div>
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            links: [
                {
                    title: "كل الطلبات",
                    slug: "/orders",
                    icon: "fi fi-sr-shopping-cart",
                    renderComponent: true,
                },
                {
                    title: "الطلبات الراجعة",
                    slug: "/orderReturns",
                    icon: "fi fi-sr-time-delete",
                    renderComponent: true,
                },
                {
                    title: "انشاء طلبية",
                    slug: "/newOrder",
                    icon: "fi fi-sr-shopping-cart-add",
                    renderComponent: true,
                },
                {
                    title: "التبويبات",
                    slug: "/clientTabs",
                    icon: "mdi-tag-multiple",
                    renderComponent: true,
                },
            ],
        };
    },

    methods: {
        async toPage(slug) {
            if (slug == "/newOrder") {
                this.openAddDialog();
            } else {
                if (slug == "/orderDuplicated") {
                    // console.log("toPage", slug);
                    this.$global.state.duplicated = true;
                }
                this.$router.push(slug);
            }
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },
    },
};
</script>


<style lang="scss" scoped>
.container {
    width: 70%;
    margin: auto;
}
</style>














